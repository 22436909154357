<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1C9.82441 1 7.69768 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM12 21C10.22 21 8.47992 20.4722 6.99988 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63605 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="#76767A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 6H10C9.73479 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V11H8C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13H9L9 14H8C7.73479 14 7.48043 14.1054 7.2929 14.2929C7.10536 14.4804 7 14.7348 7 15C7 15.2652 7.10536 15.5196 7.2929 15.7071C7.48043 15.8946 7.73479 16 8 16H9L9 17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73479 18 10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14H11V13H14C14.7956 13 15.5587 12.6839 16.1213 12.1213C16.6839 11.5587 17 10.7956 17 10V9C17 8.20435 16.6839 7.44129 16.1213 6.87868C15.5587 6.31607 14.7956 6 14 6ZM14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10V9C15 8.73478 14.8946 8.48043 14.7071 8.29289C14.5196 8.10536 14.2652 8 14 8H11V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071Z"
        fill="#76767A"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "Cost",
};
</script>
