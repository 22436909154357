<template>
  <main class="main">
    <div class="container">
      <div class="main__inner" v-if="item">
        <BreadcrumbsComponent :title="item.title" v-if="item.title" />
        <section class="section open">
          <div class="open__info_row">
            <a class="category poster_tag" v-if="item.category && item.category.title">
              {{ item.category.title }}
            </a>
            <div class="date" v-if="item.date_publication">
              <time :datetime="item.date_publication | robotDate">
                {{ item.date_publication | humanDatePosterRu(item.date_end) }}</time
              >
            </div>
          </div>
          <div class="open__row">
            <div class="open__col-content">
              <div class="open__title" v-if="item.title">
                {{ item.title }}
              </div>
              <div class="open__img">
                <div class="open__img__content">
                  <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
                </div>
              </div>
              <EditorJSComponent :text="JSON.parse(item.description)" v-if="item.description" />
              <div class="poster_details poster_details-mobile">
                <div class="poster_details__title">Детали</div>
                <ul class="poster_details__list">
                  <li class="poster_details__item" v-if="item.date">
                    <div class="inner__row">
                      <Date />
                      <div class="info__col">
                        <div class="title">Когда</div>
                        <div class="description">{{ item.date }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.place">
                    <div class="inner__row">
                      <Place />
                      <div class="info__col">
                        <div class="title">Место</div>
                        <div class="description">{{ item.place }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.cost">
                    <div class="inner__row">
                      <Cost />
                      <div class="info__col">
                        <div class="title">Стоимость</div>
                        <div class="description">{{ item.cost }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.address">
                    <div class="inner__row">
                      <Address />
                      <div class="info__col">
                        <div class="title">Адрес</div>
                        <div class="description">{{ item.address }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.phone">
                    <div class="inner__row">
                      <Phone />
                      <div class="info__col">
                        <div class="title">Телефон</div>
                        <div class="description">{{ item.phone }}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="poster__map">
                <div class="poster__map-header">
                  <MapIcon />
                  <span class="poster__map-title">Место проведения на карте</span>
                </div>
                <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
                  <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
                </yandex-map>
              </div>
            </div>
            <div class="open__col-aside open__col-aside-hide">
              <div class="poster_details">
                <div class="poster_details__title">Детали</div>
                <ul class="poster_details__list">
                  <li class="poster_details__item" v-if="item.date">
                    <div class="inner__row">
                      <Date />
                      <div class="info__col">
                        <div class="title">Когда</div>
                        <div class="description">{{ item.date }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.place">
                    <div class="inner__row">
                      <Place />
                      <div class="info__col">
                        <div class="title">Место</div>
                        <div class="description">{{ item.place }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.cost">
                    <div class="inner__row">
                      <Cost />
                      <div class="info__col">
                        <div class="title">Стоимость</div>
                        <div class="description">{{ item.cost }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.address">
                    <div class="inner__row">
                      <Address />
                      <div class="info__col">
                        <div class="title">Адрес</div>
                        <div class="description">{{ item.address }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="poster_details__item" v-if="item.phone">
                    <div class="inner__row">
                      <Phone />
                      <div class="info__col">
                        <div class="title">Телефон</div>
                        <div class="description">{{ item.phone }}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import Date from "components/svg/poster/Date.vue";
import Place from "components/svg/poster/Place.vue";
import Phone from "components/svg/poster/Phone.vue";
import Address from "components/svg/poster/Address.vue";
import Cost from "components/svg/poster/Cost.vue";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import MapIcon from "components/svg/poster/Map.vue";

export default {
  name: "posterComponent",
  async asyncData({ store, route }) {
    await store.dispatch("GET_OPEN_POSTER", route.params.id);
  },
  data() {
    return {
      coords: [0, 0],
      settings: {
        apiKey: "2accbdff-2038-4811-9f12-f3799d64a50c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map-red-pin.svg",
        imageSize: [36, 36],
        imageOffset: [-18, -18],
      },
      mapReady: false,
    };
  },
  computed: {
    item() {
      return this.$store.state.posters_open_page;
    },
  },
  mounted() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode(this.item.address).then((res) => {
          this.coords = res.geoObjects.get(0).geometry.getCoordinates();
          this.mapReady = true;
        });
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.posters_open_page = null;
    next();
  },
  metaInfo() {
    const poster = this.$store.state.posters_open_page;

    let description = "";
    if (poster.description && typeof poster.description === "string") {
      description = JSON.parse(poster.description);
      description = description.blocks ?? "";
      description = description.filter((b) => b.type === "paragraph");
      if (description.length) {
        description = description[0].data.text;
      } else {
        description = "";
      }
    }

    let img = "/public/images/no-image.png";
    if (poster.head_img && poster.head_img.img && poster.head_img.img.url) {
      img = poster.head_img.img.url;
    }
    return this.$seo(
      "common",
      poster.title || "Официальный сайт администрации города Махачкалы",
      poster.meta_keywords || "",
      description,
      poster.title || "Официальный сайт администрации города Махачкалы",
      img,
      description
    );
  },
  components: {
    MapIcon,
    BreadcrumbsComponent,
    EditorJSComponent,
    Cost,
    Place,
    Phone,
    Address,
    Date,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/news/index.styl"
@import "~@/styles/parts/mycity/poster/poster_open.styl"
.poster {
  &__map {
    display flex
    flex-flow row wrap
    margin 40px 0 16px
    order 1

    .ymap-container {
      width 100%
      height 490px
      +below(480px) {
        height 396px
      }
    }
  }

  &__map-header {
    display flex
    align-items center
    justify-content flex-start
    margin-bottom 16px

    .icon {
      width 24px
      height 24px
      margin-right 16px

      svg {
        width 100%
        height 100%
      }
    }
  }
  &__map-title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color-dark);
  }
}
</style>
