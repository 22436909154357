<template>
  <figure class="icon map-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.32 5.0503L15.32 3.0503H15.25C15.2035 3.04562 15.1566 3.04562 15.11 3.0503H14.88H14.75H14.68L9 5.0003L3.32 3.0503C3.16962 3.00071 3.00961 2.98754 2.85314 3.01188C2.69667 3.03622 2.54822 3.09737 2.42 3.1903C2.29076 3.28232 2.18527 3.40376 2.11224 3.54461C2.03921 3.68545 2.00074 3.84165 2 4.0003V18.0003C1.99946 18.2099 2.06482 18.4145 2.18685 18.5849C2.30887 18.7554 2.48138 18.8832 2.68 18.9503L8.68 20.9503C8.88145 21.016 9.09856 21.016 9.3 20.9503L15 19.0503L20.68 21.0003C20.7862 21.0147 20.8938 21.0147 21 21.0003C21.2091 21.0032 21.4132 20.9364 21.58 20.8103C21.7092 20.7183 21.8147 20.5968 21.8878 20.456C21.9608 20.3151 21.9993 20.1589 22 20.0003V6.0003C22.0005 5.79066 21.9352 5.58614 21.8132 5.41567C21.6911 5.2452 21.5186 5.11738 21.32 5.0503ZM8 18.6103L4 17.2803V5.3903L8 6.7203V18.6103ZM14 17.2803L10 18.6103V6.7203L14 5.3903V17.2803ZM20 18.6103L16 17.2803V5.3903L20 6.7203V18.6103Z"
        fill="#76767A"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "MapIcon",
};
</script>
