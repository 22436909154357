<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
        fill="#76767A"
      />
      <path
        d="M10.5858 9.71398L12 11.1282L14.8284 8.29977C15.016 8.11223 15.2703 8.00688 15.5355 8.00688C15.8008 8.00688 16.0551 8.11223 16.2426 8.29977C16.4302 8.48731 16.5355 8.74166 16.5355 9.00688C16.5355 9.27209 16.4302 9.52645 16.2426 9.71398L12.7071 13.2495C12.5196 13.4371 12.2652 13.5424 12 13.5424C11.7348 13.5424 11.4804 13.4371 11.2929 13.2495L9.17158 11.1282C8.98404 10.9407 8.87868 10.6863 8.87868 10.4211C8.87868 10.1559 8.98404 9.90152 9.17158 9.71398C9.35911 9.52645 9.61347 9.42109 9.87868 9.42109C10.1439 9.42109 10.3983 9.52645 10.5858 9.71398Z"
        fill="#76767A"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "Date",
};
</script>
